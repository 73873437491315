import React, { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import { object, string, array, ref } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { createExternalRegistrationRequest } from '../../app/reducers/ExternalRegistrationRequest/externalRegistrationRequestSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import { createEmployee } from '../../app/reducers/Users/EmployeeSlice';
import {
    CL_ROLE_ID,
    UNAPPROVED_CL_ROLE_ID,
    link_data,
} from '../../utils/constants';
import { createCollegeParticipant } from '../../app/reducers/CollegeParticipant/collegeParticipantSlice';
import { useNavigate } from 'react-router-dom';
import FormikINPhoneInput from '../../components/formik/FormikINPhoneInput';
import NavbarLeft from '../../components/Navbar/NavbarLeft';
import { getWebsiteCreatives } from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import FormikImageCropper from '../../components/formik/FormikImageCropper';

const ExternalRegistrationRequest = () => {
    const { college } = useSelector(getColleges);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchColleges({ populate: true, sort: { name: 1 } }));
    }, []);
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            username: '',
            college: '',
            collegeIdPhoto: '',
            userPhotoId: '',
            // role: '',
        },
        validationSchema: object({
            firstName: string()
                .matches(
                    /^[A-Z][a-z]*$/,
                    'Case sensitive & can not contain spaces. i.e. Hello'
                )
                .label('First Name')
                .required(),
            lastName: string()
                .matches(
                    /^[A-Z][a-z]*$/,
                    'Case sensitive & can not contain spaces. i.e. Hello'
                )
                .label('Last Name')
                .required(),
            email: string().email('Invalid email').label('Email').required(),
            password: string()
                .matches(/^\S+$/, 'Space in not allowed')
                .label('Password')
                .required(),
            confirmPassword: string()
                .label('Confirm Password')
                .required()
                .oneOf([ref('password')], 'Password must be same'),
            username: string()
                .matches(
                    /^[0-9]{10}$/,
                    'Contact Number must be numbers (exact 10 digits)'
                )
                .min(10, 'Min 10 digits')
                .max(10, 'Max 10 digits')
                .label('Contact Number')
                .required(),
            college: string().label('College').required(),
            collegeIdPhoto: string().label('College ID Photo').required(),
            userPhotoId: string().label('Photo ID').required(),
        }),
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                let allExtUser;
                if (values?.college) {
                    const payload = {
                        college: values.college,
                    };
                    const string = QueryString.stringify(payload);
                    const respData = await authAxiosInstance.get(
                        `users/employee?${string}`
                    );
                    allExtUser = respData?.data?.data?.docs;
                }
                if (allExtUser.length > 0) {
                    toast.error('User already exist from this college');
                    setSubmitting(false);
                    return;
                }
                const obj = {
                    ...values,
                    role: UNAPPROVED_CL_ROLE_ID,
                    partOfO2: 'No',
                };
                const resp = await dispatch(createCollegeParticipant(obj));
                if (resp?.payload?.success) {
                    navigate(
                        `/externalRegistrationGreetPage/${resp?.payload?.data?.data?._id}`
                    );
                }
                resetForm();
            } catch (error) {
                toast.error(error.message)
            } finally {
                setSubmitting(false);
            }
        },
    });
    return (
        // <>
        //     <NavbarLeft props={link_data} />
        //     <main className="bg-black">
        //         <div className="relative md:flex pt-[50px] min-h-screen">
        //             <div
        //                 className="hidden md:w-1/2  md:flex "
        //                 aria-hidden="true"
        //                 style={{
        //                     backgroundColor: '#000000',
        //                 }}
        //             >
        //                 {/* <div className='w-[400px] h-[400px] lg:w-[500px] lg:h-[500px] mx-auto'> */}
        //                 <div className="w-full h-full">
        //                     <img
        //                         className="object-cover w-full h-full"
        //                         style={{
        //                             objectPosition: 'center center',
        //                         }}
        //                         src={`${process.env.REACT_APP_S3_URL}${dynamicDefaultData?.banner}`}
        //                         // width="760"
        //                         // height="1024"
        //                         alt="Authentication"
        //                     />
        //                 </div>
        //             </div>
        <div className="md:w-1/2 md:flex md:flex-col md:item-center">
            {/* <div className="md:flex md:flex-col md:item-center md:justify-center"> */}
            <div className="w-full lg:w-2/3  mx-auto px-4 py-8">
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
                >
                    <div className="text-left ">
                        <h1 className="text-3xl text-slate-200 font-bold mb-2 ">
                            Welcome 👋
                        </h1>
                        <p className="text-slate-200 text-sm mb-2 ">
                            Fill this form to register
                        </p>
                    </div>
                    <div className="flex flex-col gap-4 sm:flex-row w-full">
                        <div className="w-full">
                            <FormikInputGroup
                                name="firstName"
                                required
                                formik={formik}
                                label="First Name"
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup
                                name="lastName"
                                required
                                formik={formik}
                                label="Last Name"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 sm:flex-row w-full">
                        <div className="w-full">
                            <FormikINPhoneInput
                                name="username"
                                label="Contact Number"
                                formik={formik}
                                required
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup
                                name="email"
                                required
                                formik={formik}
                                label="Email"
                            />
                        </div>
                    </div>
                    <FormikSelectGroup
                        name="college"
                        label="College"
                        formik={formik}
                        required
                        options={generateOptions({
                            array: college ? college?.docs : [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                        onChange={async (selectedOption) => {
                            const payload = {
                                role: CL_ROLE_ID,
                                college: selectedOption.value,
                            };

                            const string = QueryString.stringify(payload);
                            const allUsers = await authAxiosInstance.get(
                                `/users/employee?${string}`
                            );

                            const collegeUsers = allUsers?.data?.data?.docs;
                            const collegeOption = college?.docs?.find(
                                (ele) => ele?._id == selectedOption.value
                            );

                            const seatOption = collegeOption?.volunteers?.find(
                                (ele) => ele?.role === CL_ROLE_ID
                            );
                            if (collegeUsers.length >= seatOption?.seats) {
                                toast.error(
                                    `Seats are full for ${selectedOption.label} `
                                );
                            } else {
                                formik.setFieldValue(
                                    'college',
                                    selectedOption.value
                                );
                            }
                        }}
                    />
                    <FormikImageCropper
                        name="userPhotoId"
                        label="Photo For ID"
                        formik={formik}
                        location={`1648_entertainment/externalRegistered/`}
                        required
                        randomize
                        dynamicDefaultData={dynamicDefaultData}
                    />
                    <FormikDirectImageUpload
                        name="collegeIdPhoto"
                        label="College ID Photo"
                        formik={formik}
                        location={`1648_entertainment/externalRegistered/`}
                        required
                        randomize
                    />
                    {/* <div className="flex flex-col gap-4 sm:flex-row w-full"> */}
                    {/* <div className="w-full"> */}
                    <FormikInputGroup
                        name="password"
                        required
                        formik={formik}
                        label="Password"
                        type="password"
                    />
                    {/* </div> */}
                    {/* <div className="w-full"> */}
                    <FormikInputGroup
                        name="confirmPassword"
                        required
                        formik={formik}
                        label="Confirm Password"
                        type="password"
                    />
                    {/* </div> */}
                    {/* </div> */}

                    {/* <FormikDirectFileUpload
                                    name="collegeIdPhoto"
                                    label="College Id Photo"
                                    formik={formik}
                                    location={`1648_entertainment/externalRegistered/collegeId`}
                                    required
                                    randomize
                                />
                                <FormikDirectFileUpload
                                    name="govtIdPhoto"
                                    label="Govt Id Photo"
                                    formik={formik}
                                    location={`1648_entertainment/externalRegistered/GovtId`}
                                    required
                                    randomize
                                /> */}
                    {/* <FormikSelectGroup
                                    name="role"
                                    label="Role"
                                    formik={formik}
                                    required
                                    options={generateOptions({
                                        array: role ? role?.docs?.filter((ele)=> ele.name != 'ACL') : [],
                                        labelField: 'name',
                                        valueField: '_id',
                                    })}
                                /> */}
                    <div className="text-center mt-2">
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                            className=" sm:w-[40%]"
                            style={{
                                backgroundColor:
                                    dynamicDefaultData?.highlightedText
                                        ? dynamicDefaultData?.highlightedText
                                        : '',
                            }}
                        >
                            Submit
                        </PrimaryButton>
                    </div>
                </form>
            </div>
            {/* </div> */}
        </div>
        //         </div>
        //     </main>
        // </>
    );
};

export default ExternalRegistrationRequest;
