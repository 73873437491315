import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit2, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';

import PageWithCard from '../../components/infrastructure/PageWithCard';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import {
    deleteEmployee,
    fetchEmployees,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { useNavigate } from 'react-router-dom';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import {
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import FilterEmployees from './FilterEmplyees';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import ChangePassword from './ChangePassword';
import { deleteRegistrationRequest } from '../../app/reducers/RegistrationRequest/registrationRequestSlice';

const ViewAllEmployees = () => {
    const navigate = useNavigate();
    const { employees, loading } = useSelector(getEmployees);
    const { role } = useSelector(getRoles);
    const { committee } = useSelector(getCommittees);
    const { college } = useSelector(getColleges);
    const { baseUrl } = useSelector(getMediaLibrary);
    const [requestedData, setRequestData] = useState({});
    const [showChangePasswordModal, setShowChangePasswordModal] =
        useState(false);
    const dispatch = useDispatch();
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(fetchEmployees({ search: search, populate: true }));
        }, 300),
        []
    );
    const [page, setPage] = useState(1);

    const cols = [
        {
            Header: 'Name',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.firstName} {row?.original?.lastName}
                    </span>
                );
            },
        },
        {
            Header: 'Phone',
            accessor: 'username',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Role',
            Cell: ({ row }) => {
                return <span>{row?.original?.role?.name}</span>;
            },
        },
        // {
        //     Header: 'Committee',
        //     Cell: ({ row }) => {
        //         const committeeData = committee?.docs?.filter((item) =>
        //             row?.original?.committee.find((ele) => ele === item?._id)
        //         );
        //         return (
        //             <div className="flex flex-col">
        //                 {committeeData?.map((ele, i) => {
        //                     return <span key={i}>{ele?.name}</span>;
        //                 })}
        //             </div>
        //         );
        //     },
        // },
        // {
        //     Header: 'College',
        //     Cell: ({ row }) => {
        //         const collegeData = college?.docs?.find(
        //             (ele) => ele._id === row?.original?.college
        //         );
        //         return <span>{collegeData?.name}</span>;
        //     },
        // },
        // {
        //     Header : 'Courses',
        //     accessor : 'courses'
        // },
        // {
        //     Header : 'Year',
        //     accessor : 'year'
        // },
        // {
        //     Header : 'Roll No.',
        //     accessor : 'rollNo'
        // },
        {
            Header : 'Locked Status',
            Cell :({row})=>{
                return (
                    <span className='text-red-500'>{row?.original?.isAccountLocked === true ? 'Locked' : ''}</span>
                )
            }
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 items-center cursor-pointer">
                        {/* <PrimaryButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                navigate(
                                    `/users/editAllEmployee/${row?.original._id}` 
                                );
                            }}
                            color="blue"
                        >
                             Edit 
                        </PrimaryButton> */}
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setRequestData(row?.original);
                                setShowChangePasswordModal(true);
                            }}
                        >
                            Change Password
                        </PrimaryButton>
                        <DangerButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                const confirmSubmit = window.confirm(
                                    'Are you sure you want to delete ?'
                                );
                                if (confirmSubmit) {
                                    const registrationPayload = {
                                        username: row?.original?.username,
                                    };
                                    const registrationString =
                                        QueryString.stringify(
                                            registrationPayload
                                        );
                                    const registrationResp =
                                        await authAxiosInstance.get(
                                            `/registrationRequest?${registrationString}`
                                        );
                                    const registrationData =
                                        registrationResp?.data?.data?.docs;
                                    if (registrationData?.length) {
                                        const deleteId =
                                            registrationData[0]?._id;
                                        await dispatch(
                                            deleteRegistrationRequest({
                                                id: deleteId,
                                            })
                                        );
                                    }
                                    await dispatch(
                                        deleteEmployee({
                                            id: row?.original._id,
                                        })
                                    );
                                    await dispatch(
                                        fetchEmployees({
                                            page,
                                            populate: true,
                                            sort: { firstName: 1 },
                                        })
                                    );
                                }
                            }}
                        >
                            Delete
                        </DangerButton>
                    </div>
                );
            },
        },
    ];
    const data = useMemo(
        () => (employees?.docs ? employees.docs : []),
        [employees]
    );
    useEffect(() => {
        dispatch(
            fetchEmployees({ page, populate: true, sort: { firstName: 1 } })
        );
        dispatch(fetchRoles({ populate: true }));
        dispatch(fetchCommittees({ populate: true }));
        dispatch(fetchColleges({ populate: true }));
    }, [page]);
    return (
        <PageWithCard heading="User">
            {/* <FilterEmployees loading={loading}/> */}
            {
                <ChangePassword
                    requestedData={requestedData}
                    showChangePasswordModal={showChangePasswordModal}
                    setShowChangePasswordModal={setShowChangePasswordModal}
                />
            }
            <TableWithHeadingAndGlobalSearch
                loading={loading}
                data={data}
                searchFunction={debouncedSearch}
                columns={cols}
            />
            <PaginationClassic
                setPage={setPage}
                paginationDetails={employees}
            />
        </PageWithCard>
    );
};

export default ViewAllEmployees;
